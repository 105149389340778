<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <div class="d-flex align-items-center">
        <b-badge variant="light-primary" pill class="mr-2">
          {{ totalGames }} juegos disponibles
        </b-badge>
        
        <!-- Selector de elementos por página -->
        <b-form-select
          v-model="localPerPage"
          size="sm"
          :options="perPageOptions"
          class="per-page-selector"
        />
      </div>
      
      <b-button
        v-if="games.length > 0"
        variant="outline-primary"
        size="sm"
        @click="$emit('add-all')"
        :disabled="isProcessingBatch"
      >
        <b-spinner v-if="isProcessingBatch" small></b-spinner>
        <feather-icon v-else icon="PlusIcon" size="14" class="mr-1" />
        Añadir todos
      </b-button>
    </div>

    <!-- Grid de juegos disponibles -->
    <div class="games-grid">
      <game-card
        v-for="game in games"
        :key="game._id"
        :game="game"
        action-type="add"
        :processing="processingGame === game._id"
        @action-click="$emit('add-game', game)"
      />

      <div v-if="games.length === 0" class="text-center text-muted p-5">
        <feather-icon icon="SearchIcon" size="36" />
        <p class="mt-2">No hay juegos disponibles con los filtros actuales</p>
      </div>
    </div>

    <!-- Paginación -->
    <div class="d-flex justify-content-center mt-3">
      <b-pagination
        v-if="games.length > 0 && totalGames > localPerPage"
        v-model="localCurrentPage"
        :total-rows="totalGames"
        :per-page="localPerPage"
        align="center"
        first-number
        last-number
      />
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BSpinner,
  BPagination,
  BFormSelect
} from "bootstrap-vue";
import GameCard from "./GameCard.vue";

export default {
  name: 'AvailableGames',
  components: {
    BBadge,
    BButton,
    BSpinner,
    BPagination,
    BFormSelect,
    GameCard
  },
  props: {
    games: {
      type: Array,
      required: true
    },
    totalGames: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      default: 12
    },
    currentPage: {
      type: Number,
      default: 1
    },
    processingGame: {
      type: String,
      default: null
    },
    isProcessingBatch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localCurrentPage: this.currentPage,
      localPerPage: this.perPage,
      perPageOptions: [
        { value: 12, text: '12 por página' },
        { value: 100, text: '100 por página' },
        { value: 200, text: '200 por página' },
        { value: 1000, text: '1000 por página' }
      ]
    };
  },
  watch: {
    currentPage(newVal) {
      // Actualizar el valor local cuando cambia la prop
      this.localCurrentPage = newVal;
    },
    localCurrentPage(newVal) {
      // Emitir evento cuando cambia la página localmente
      if (newVal !== this.currentPage) {
        this.$emit('page-change', newVal);
      }
    },
    perPage(newVal) {
      this.localPerPage = newVal;
    },
    localPerPage(newVal) {
      if (newVal !== this.perPage) {
        this.$emit('per-page-change', newVal);
        // Resetear a la primera página cuando cambia el tamaño de página
        this.localCurrentPage = 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.games-grid {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 58vh;
  overflow-y: auto;
  padding: 0.25rem;
}

.per-page-selector {
  width: 150px;
}
</style>
