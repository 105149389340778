<template>
  <b-card class="filter-card">
    <b-row>
      <b-col md="4">
        <b-form-group label="Proveedor">
          <v-select
            v-model="selectedProviderModel"
            :options="providersOptions"
            label="name"
            :reduce="provider => provider._id"
            placeholder="Seleccione proveedores"
            multiple
            :loading="loadingProviders"
          />
        </b-form-group>
      </b-col>
      
      <b-col md="4">
        <b-form-group label="Marca">
          <v-select
            v-model="selectedBrandModel"
            :options="brandsOptions"
            label="name"
            :reduce="brand => brand._id"
            placeholder="Seleccione marcas"
            multiple
            :disabled="!selectedProviderModel || selectedProviderModel.length === 0"
            :loading="loadingBrands"
          />
        </b-form-group>
      </b-col>
      
      <b-col md="4">
        <b-form-group label="Buscar">
          <b-input-group>
            <b-form-input
              v-model="searchQuery"
              placeholder="Buscar juegos"
              @keyup.enter="search"
            />
            <b-input-group-append>
              <b-button variant="outline-primary" @click="search">
                <feather-icon icon="SearchIcon" size="16" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Estado de Juegos">
          <div class="status-switch-container">
            <div class="status-switch">
              <b-form-checkbox
                v-model="statusFilterModel"
                switch
                size="lg"
                @change="onStatusFilterChange"
              >
              <small class="text-muted status-help-text">
              {{ statusFilterModel ? 'Mostrando juegos activos' : 'Mostrando juegos inactivos' }}
            </small>
              </b-form-checkbox>
            </div>
            
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: 'GamesFilter',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    vSelect
  },
  props: {
    providersOptions: {
      type: Array,
      default: () => []
    },
    brandsOptions: {
      type: Array,
      default: () => []
    },
    selectedProvider: {
      type: Array,
      default: () => []
    },
    selectedBrand: {
      type: Array,
      default: () => []
    },
    searchGameQuery: {
      type: String,
      default: ''
    },
    loadingProviders: {
      type: Boolean,
      default: false
    },
    loadingBrands: {
      type: Boolean,
      default: false
    },
    statusFilter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedProviderModel: this.selectedProvider,
      selectedBrandModel: this.selectedBrand,
      searchQuery: this.searchGameQuery,
      statusFilterModel: this.statusFilter
    };
  },
  watch: {
    selectedProvider(val) {
      this.selectedProviderModel = val;
    },
    selectedBrand(val) {
      this.selectedBrandModel = val;
    },
    searchGameQuery(val) {
      this.searchQuery = val;
    },
    statusFilter(val) {
      this.statusFilterModel = val;
    },
    selectedProviderModel(val) {
      this.$emit('provider-selected', val);
    },
    selectedBrandModel(val) {
      this.$emit('brand-selected', val);
    }
  },
  methods: {
    search() {
      this.$emit('search', this.searchQuery);
    },
    onStatusFilterChange(val) {
      this.$emit('status-filter-change', val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-card {
  background-color: rgba(0, 0, 0, 0.02);
}

.status-switch-container {
  display: flex;
  flex-direction: column;
}


.status-help-text {
  font-size: 0.7rem;
  margin-top: -0.25rem;
}

// Mejoras para el switch
::v-deep .custom-switch {
  padding-left: 2.25rem;
  
  .custom-control-label {
    &::before {
      height: 1.25rem;
      width: 2rem;
      border-radius: 0.625rem;
    }
    
    &::after {
      width: calc(1.25rem - 4px);
      height: calc(1.25rem - 4px);
      border-radius: 0.625rem;
    }
  }
  
  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(0.75rem);
  }
}
</style>
