<template>
  <div>
    <div v-if="hasDuplicates">
      <div class="mb-3 alert alert-warning">
        <feather-icon icon="AlertTriangleIcon" size="18" class="mr-1" />
        Se han detectado juegos duplicados en esta etiqueta.
      </div>

      <div class="duplicate-games-container">
        <template v-for="(group, index) in duplicateGroups">
          <div class="duplicate-group mb-4">
            <h6 class="duplicate-title">
              Grupo duplicado: {{ group.gameName }}
            </h6>
            
            <b-list-group>
              <b-list-group-item
                v-for="game in group.games"
                :key="game._id"
                class="d-flex justify-content-between align-items-center"
              >
                <div class="d-flex align-items-center">
                  <b-img
                    :src="game.url_image"
                    width="40"
                    height="40"
                    rounded="sm"
                    class="mr-2"
                  />
                  <div>
                    <div>{{ game.gameName }}</div>
                    <div>
                      <b-badge variant="light-primary">
                        {{ game.product ? game.product.name : '-' }}
                      </b-badge>
                    </div>
                  </div>
                </div>
                
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click="$emit('remove-duplicate', game)"
                  :disabled="processingGame === game._id"
                >
                  <b-spinner v-if="processingGame === game._id" small></b-spinner>
                  <feather-icon v-else icon="TrashIcon" size="16" />
                </b-button>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="text-center text-muted p-5">
      <feather-icon icon="CheckCircleIcon" size="36" />
      <p class="mt-2">No hay juegos duplicados en esta etiqueta</p>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BImg
} from "bootstrap-vue";

export default {
  name: 'DuplicateGames',
  components: {
    BBadge,
    BButton,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BImg
  },
  props: {
    duplicateGroups: {
      type: Array,
      required: true
    },
    processingGame: {
      type: String,
      default: null
    }
  },
  computed: {
    hasDuplicates() {
      return this.duplicateGroups.length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.duplicate-games-container {
  max-height: 60vh;
  overflow-y: auto;
  
  .duplicate-title {
    background-color: rgba(var(--warning-rgb), 0.1);
    padding: 0.5rem;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
}
</style>
