<template>
  <b-modal
    v-model="isVisible"
    :title="isEdit ? 'Editar etiqueta' : 'Añadir etiqueta'"
    @hidden="resetForm"
    @ok.prevent="submitForm"
    hide-footer
    centered
  >
    <validation-observer ref="tagFormObserver" v-slot="{ invalid }">
      <b-form @submit.prevent="submitForm">
        <validation-provider name="name" rules="required" v-slot="{ errors }">
          <b-form-group
            label="Nombre de etiqueta"
            :state="errors.length === 0"
          >
            <b-form-input
              v-model="form.name"
              :state="errors.length === 0"
              placeholder="Ejemplo: Populares, Nuevos, etc."
              required
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="status">
          <b-form-group label="Estado">
            <b-form-select v-model="form.status" :options="[
              { value: true, text: 'Activo' },
              { value: false, text: 'Inactivo' },
            ]" />
          </b-form-group>
        </validation-provider>

        <div class="d-flex justify-content-end mt-3">
          <b-button
            variant="secondary"
            class="mr-2"
            @click="isVisible = false"
          >
            Cancelar
          </b-button>
          
          <b-button
            variant="primary"
            type="submit"
            :disabled="invalid || saving"
          >
            <b-spinner v-if="saving" small></b-spinner>
            {{ isEdit ? 'Actualizar' : 'Crear' }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormInvalidFeedback,
  BButton,
  BSpinner
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  name: 'TagForm',
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tag: {
      type: Object,
      default: null
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
        required,
      isVisible: false,
      form: {
        id: null,
        name: '',
        status: true
      }
    };
  },
  computed: {
    isEdit() {
      return !!this.form.id;
    }
  },
  watch: {
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    },
    tag: {
      handler(val) {
        if (val) {
          this.form = {
            id: val._id,
            name: val.name,
            status: val.status
          };
        } else {
          this.resetForm();
        }
      },
      immediate: true
    }
  },
  methods: {
    resetForm() {
      this.form = {
        id: null,
        name: '',
        status: true
      };
      
      if (this.$refs.tagFormObserver) {
        this.$refs.tagFormObserver.reset();
      }
    },
    
    async submitForm() {
      const isValid = await this.$refs.tagFormObserver.validate();
      if (!isValid) return;
      
      const tagData = {
        name: this.form.name.trim(),
        status: this.form.status
      };
      
      if (this.form.id) {
        tagData._id = this.form.id;
      }
      
      this.$emit('save', tagData, this.isEdit);
    }
  }
};
</script>
