<template>
  <b-card no-body class="game-card mb-2">
    <div class="p-2 d-flex align-items-center">
      <div v-if="showDragHandle" class="drag-handle mr-2 cursor-move">
        <feather-icon icon="MenuIcon" size="16" class="text-muted" />
      </div>
      
      <b-badge 
        v-if="position && !isEditingPosition" 
        pill 
        variant="light-primary" 
        class="position-badge mr-2 cursor-pointer"
        @click="startEditingPosition"
        v-b-tooltip.hover.top="'Clic para editar posición'"
      >
        {{ position }}
      </b-badge>

      <b-form-input
        v-if="isEditingPosition"
        ref="positionInput"
        v-model="editedPosition"
        type="number"
        size="sm"
        class="position-input mr-2"
        min="1"
        @keyup.enter="savePosition"
        @keyup.esc="cancelEditing"
        @blur="savePosition"
      />

      <b-img
        :src="game.url_image"
        width="50"
        height="50"
        rounded="sm"
        class="mr-2 game-thumbnail"
      />
      
      <div class="flex-grow-1 game-details">
        <h6 class="mb-0">{{ game.gameName }}</h6>
        <div class="game-info">
          <b-badge pill variant="light-primary" class="mr-1">
            {{ game.product ? game.product.name : '-' }}
          </b-badge>
          <b-badge pill variant="light-secondary" class="mr-1">
            {{ game.brandName || '-' }}
          </b-badge>
          <b-badge 
            pill 
            :variant="game.status ? 'light-success' : 'light-danger'"
            v-b-tooltip.hover.top="game.status ? 'Juego activo' : 'Juego inactivo'"
          >
            {{ game.status ? 'Activo' : 'Inactivo' }}
          </b-badge>
        </div>
      </div>
      
      <b-button
        v-if="showMoveToTop"
        variant="light-secondary"
        size="sm"
        class="mr-2 move-to-top-btn"
        v-b-tooltip.hover.top="'Mover al inicio'"
        @click="onMoveToTop"
      >
        <feather-icon icon="ArrowUpIcon" size="14" />
      </b-button>
      
      <b-button
        :variant="actionVariant"
        size="sm"
        @click="onActionClick"
        :disabled="processing"
      >
        <b-spinner v-if="processing" small></b-spinner>
        <feather-icon v-else :icon="actionIcon" size="16" />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BButton,
  BSpinner,
  BImg,
  VBTooltip,
  BFormInput
} from "bootstrap-vue";

export default {
  name: 'GameCard',
  components: {
    BCard,
    BBadge,
    BButton,
    BSpinner,
    BImg,
    BFormInput
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      isEditingPosition: false,
      editedPosition: null
    }
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      default: null
    },
    showDragHandle: {
      type: Boolean,
      default: false
    },
    actionType: {
      type: String,
      default: 'add', // 'add' o 'remove'
      validator: value => ['add', 'remove'].includes(value)
    },
    processing: {
      type: Boolean,
      default: false
    },
    showMoveToTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    actionVariant() {
      return this.actionType === 'add' ? 'outline-primary' : 'outline-danger';
    },
    actionIcon() {
      return this.actionType === 'add' ? 'PlusIcon' : 'TrashIcon';
    }
  },
  methods: {
    startEditingPosition() {
      if (!this.position) return;
      this.editedPosition = this.position;
      this.isEditingPosition = true;
      this.$nextTick(() => {
        this.$refs.positionInput.focus();
      });
    },
    savePosition() {
      if (!this.editedPosition) {
        this.cancelEditing();
        return;
      }
      
      const newPosition = parseInt(this.editedPosition, 10);
      if (isNaN(newPosition) || newPosition < 1) {
        this.cancelEditing();
        return;
      }
      
      this.isEditingPosition = false;
      if (newPosition !== this.position) {
        this.$emit('position-change', {
          game: this.game,
          newPosition: newPosition
        });
      }
    },
    cancelEditing() {
      this.isEditingPosition = false;
      this.editedPosition = null;
    },
    onActionClick() {
      this.$emit('action-click', this.game);
    },
    onMoveToTop() {
      this.$emit('move-to-top', this.game);
    }
  }
};
</script>

<style lang="scss" scoped>
.game-card {
  transition: all 0.2s ease;
  border: 1px solid #eee;
  
  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .game-thumbnail {
    object-fit: cover;
    background-color: #f8f8f8;
  }
  
  .game-details {
    overflow: hidden;
    
    h6 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .position-badge {
    min-width: 24px;
    text-align: center;
    cursor: pointer;
    
  
  }

  .position-input {
    width: 60px;
    min-width: 60px;
    text-align: center;
  }

  .move-to-top-btn {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}

.drag-handle {
  cursor: move !important;
}
</style>
