import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  methods: {
    /**
     * Carga los juegos asignados a una etiqueta
     * @param {Number} page - Número de página a cargar
     */
    async loadTaggedGames(page = 1) {
      if (!this.selectedTag) return;
      
      try {
        this.loadingGames = true;
        
        // Obtener los juegos del tag seleccionado
        const response = await this.$store.dispatch("tagsModule/getGamesByTag", this.selectedTag._id);
        
        this.taggedGames = response.map(game => ({
          ...game,
          position: game.positionByTag?.find(p => p.tagId === this.selectedTag._id)?.position || 0
        }));
        
        // Ordenar por posición
        this.taggedGames.sort((a, b) => a.position - b.position);
        
        this.totalTaggedGames = this.taggedGames.length;
        this.positionsChanged = false;
      } catch (error) {
        console.error("Error cargando juegos etiquetados:", error);
        this.showToast({
          title: "Error cargando juegos etiquetados",
          variant: "danger"
        });
      } finally {
        this.loadingGames = false;
      }
    },

    /**
     * Carga los juegos disponibles para añadir a una etiqueta
     * @param {Number} page - Número de página a cargar
     */
    async loadAvailableGames(page = 1) {
      if (!this.selectedProvider || this.selectedProvider.length === 0 || !this.selectedTag) return;
      
      try {
        this.loadingGames = true;
        
        const params = {
          productsId: this.selectedProvider,
          brandId: this.selectedBrand,
          page: page,
          tagId: this.selectedTag._id,
          limit: this.perPage,
          search: this.searchGameQuery.trim() || undefined,
          status: this.gamesStatusFilter // Añado el filtro de estado
        };
        
        // Agregar filtro de marcas si hay marcas seleccionadas
        if (this.selectedBrand && this.selectedBrand.length > 0) {
          params.brandId = this.selectedBrand;
        }
        
        const response = await this.$store.dispatch("tagsModule/getGames", params);
        
        this.availableGames = response.data || [];
        this.totalAvailableGames = response.total || 0;
      } catch (error) {
        console.error("Error cargando juegos disponibles:", error);
        this.showToast({
          title: "Error cargando juegos disponibles",
          variant: "danger"
        });
      } finally {
        this.loadingGames = false;
      }
    },

    /**
     * Añade un juego a la etiqueta seleccionada
     * @param {Object} game - Juego a añadir
     */
    async addGameToTag(game) {
      if (!this.selectedTag) return;
      
      try {
        this.processingGame = game._id;
        
        await this.$store.dispatch("tagsModule/addTagGames", {
          tagId: this.selectedTag._id,
          data: {
            status: true,
            gameId: game.gameId,
            idGame: game._id,
            providers: this.selectedProvider // Ya es un array
          }
        });
        
        // Recargar ambas listas de juegos
        await Promise.all([
          this.loadAvailableGames(),
          this.loadTaggedGames(),
          this.loadTags()
        ]);
        
        this.showToast({
          title: "Juego añadido a la etiqueta",
          variant: "success"
        });
      } catch (error) {
        console.error("Error añadiendo juego a la etiqueta:", error);
        this.showToast({
          title: "Error añadiendo juego a la etiqueta",
          variant: "danger"
        });
      } finally {
        this.processingGame = null;
      }
    },

    /**
     * Elimina un juego de la etiqueta seleccionada
     * @param {Object} game - Juego a eliminar
     */
    async removeGameFromTag(game) {
      if (!this.selectedTag) return;
      
      try {
        this.processingGame = game._id;
        
        await this.$store.dispatch("tagsModule/addTagGames", {
          tagId: this.selectedTag._id,
          data: {
            status: false,
            gameId: game.gameId,
            idGame: game._id
          }
        });
        
        // Recargar ambas listas de juegos
        await Promise.all([
          this.loadAvailableGames(),
          this.loadTaggedGames()
        ]);
        
        this.showToast({
          title: "Juego eliminado de la etiqueta",
          variant: "success"
        });
      } catch (error) {
        console.error("Error eliminando juego de la etiqueta:", error);
        this.showToast({
          title: "Error eliminando juego de la etiqueta",
          variant: "danger"
        });
      } finally {
        this.processingGame = null;
      }
    },

    /**
     * Guarda las posiciones de los juegos en la etiqueta
     */
    async savePositions() {
      if (!this.selectedTag || !this.positionsChanged) return;
      
      try {
        this.savingPositions = true;
        
        // Actualizar las posiciones de los juegos
        const updatedGames = this.taggedGames.map((game, index) => ({
          _id: game._id,
          position: index + 1
        }));
        
        await this.$store.dispatch("tagsModule/updateIndexGames", {
          tagId: this.selectedTag._id,
          data: {
            games: updatedGames
          }
        });
        
        this.positionsChanged = false;
        this.showToast({
          title: "Posiciones guardadas correctamente",
          variant: "success"
        });
      } catch (error) {
        console.error("Error guardando posiciones:", error);
        this.showToast({
          title: "Error guardando posiciones",
          variant: "danger"
        });
      } finally {
        this.savingPositions = false;
      }
    },

    /**
     * Verifica si hay juegos duplicados en la lista
     * @param {Array} games - Lista de juegos a verificar
     * @return {Boolean} - Indica si hay duplicados
     */
    hasDuplicateGames(games) {
      if (!Array.isArray(games) || games.length === 0) return false;
      
      const gameNames = games.map(game => game.gameName);
      return new Set(gameNames).size !== gameNames.length;
    },

    /**
     * Obtiene los grupos de juegos duplicados
     * @return {Array} - Grupos de juegos duplicados
     */
    getDuplicateGroups() {
      if (!this.selectedTag || !this.taggedGames.length) return [];
      
      const duplicateGroups = [];
      const gameNameMap = new Map();
      
      this.taggedGames.forEach(game => {
        const gameName = game.gameName;
        if (!gameNameMap.has(gameName)) {
          gameNameMap.set(gameName, []);
        }
        gameNameMap.get(gameName).push(game);
      });
      
      // Filtrar solo grupos con duplicados
      gameNameMap.forEach((games, gameName) => {
        if (games.length > 1) {
          duplicateGroups.push({
            gameName: gameName,
            games: games
          });
        }
      });
      
      return duplicateGroups;
    },

    /**
     * Muestra un toast con el mensaje especificado
     * @param {Object} options - Opciones del toast
     */
    showToast(options) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: options.title,
          icon: options.variant === "success" ? "CheckCircleIcon" : "AlertTriangleIcon",
          variant: options.variant,
        },
      });
    }
  }
};
