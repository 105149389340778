<template>
  <b-card class="mb-0">
    <b-overlay :show="loading" rounded="sm">
      <!-- Vista cuando no hay tag seleccionado -->
      <div v-if="!selectedTag" class="text-center my-5">
        <feather-icon icon="LayersIcon" size="48" class="text-muted mb-2" />
        <h5 class="text-muted">Selecciona una etiqueta para gestionar sus juegos</h5>
      </div>

      <!-- Vista cuando hay tag seleccionado -->
      <div v-else>
        <!-- Header del tag -->
        <div class="tag-header mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h3 class="mb-0">{{ selectedTag.name }}</h3>
            <div class="mt-1">
              <b-badge :variant="selectedTag.status ? 'light-success' : 'light-secondary'" pill class="mr-1">
                {{ selectedTag.status ? 'Activo' : 'Inactivo' }}
              </b-badge>
              <span class="text-muted">{{ taggedGames.length }} juegos</span>
            </div>
          </div>
          <div>
            <b-button-group>
              <b-button variant="outline-primary" size="sm" @click="$emit('edit-tag', selectedTag)">
                <feather-icon icon="EditIcon" size="16" />
              </b-button>
              <b-button variant="outline-danger" size="sm" @click="$emit('delete-tag', selectedTag._id)">
                <feather-icon icon="TrashIcon" size="16" />
              </b-button>
            </b-button-group>
          </div>
        </div>

      
        <!-- Pestañas para gestionar juegos -->
        <b-tabs content-class="mt-2" fill>
          <!-- Pestaña: Juegos asignados -->
          <b-tab title="Juegos Asignados" active>
            <tagged-games
              :games="taggedGames"
              :total-games="totalTaggedGames"
              :per-page="perPage"
              :current-page="taggedGamesCurrPage"
              :processing-game="processingGame"
              :is-processing-batch="isProcessingBatch"
              :saving-positions="savingPositions"
              :positions-changed="positionsChanged"
              @update:games="updateTaggedGames"
              @page-change="onTaggedGamesPageChange"
              @per-page-change="onTaggedGamesPerPageChange"
              @remove-game="removeGameFromTag"
              @remove-all="$emit('remove-all-games')"
              @positions-changed="onPositionsChanged"
              @save-positions="$emit('save-positions')"
            />
          </b-tab>

          <!-- Pestaña: Añadir juegos -->
          <b-tab title="Añadir Juegos">
            <!-- Filtros y búsqueda de juegos -->
        <games-filter
          :providers-options="providersOptions"
          :brands-options="brandsOptions"
          :selected-provider="selectedProvider"
          :selected-brand="selectedBrand"
          :search-game-query="searchGameQuery"
          :status-filter="statusFilter"
          :loading-providers="loadingProviders"
          :loading-brands="loadingBrands"
          @provider-selected="onProviderSelected"
          @brand-selected="onBrandSelected"
          @search="onSearch"
          @status-filter-change="$emit('status-filter-change', $event)"
        />

            <available-games
              :games="availableGames"
              :total-games="totalAvailableGames"
              :per-page="perPage"
              :current-page="availableGamesCurrPage"
              :processing-game="processingGame"
              :is-processing-batch="isProcessingBatch"
              @page-change="onAvailableGamesPageChange"
              @per-page-change="onAvailableGamesPerPageChange"
              @add-game="addGameToTag"
              @add-all="$emit('add-all-games')"
            />
          </b-tab>

          <!-- Pestaña: Duplicados -->
          <b-tab title="Duplicados" :disabled="!hasDuplicateGames(taggedGames)">
            <duplicate-games
              :duplicate-groups="duplicateGroups"
              :processing-game="processingGame"
              @remove-duplicate="removeDuplicateGame"
            />
          </b-tab>
        </b-tabs>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BOverlay,
  BBadge,
  BTabs,
  BTab,
  BButtonGroup,
  BButton
} from "bootstrap-vue";
import GamesFilter from "../filters/GamesFilter.vue";
import TaggedGames from "./TaggedGames.vue";
import AvailableGames from "./AvailableGames.vue";
import DuplicateGames from "./DuplicateGames.vue";
import gamesMixin from "../../mixins/gamesMixin";

export default {
  name: 'GamesPanel',
  components: {
    BCard,
    BOverlay,
    BBadge,
    BTabs,
    BTab,
    BButtonGroup,
    BButton,
    GamesFilter,
    TaggedGames,
    AvailableGames,
    DuplicateGames
  },
  mixins: [gamesMixin],
  props: {
    selectedTag: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingProviders: {
      type: Boolean,
      default: false
    },
    loadingBrands: {
      type: Boolean,
      default: false
    },
    providersOptions: {
      type: Array,
      default: () => []
    },
    brandsOptions: {
      type: Array,
      default: () => []
    },
    selectedProvider: {
      type: Array,
      default: () => []
    },
    selectedBrand: {
      type: Array,
      default: () => []
    },
    searchGameQuery: {
      type: String,
      default: ''
    },
    statusFilter: { // Propiedad para el nuevo filtro de estado
      type: Boolean,
      default: true
    },
    taggedGames: {
      type: Array,
      default: () => []
    },
    availableGames: {
      type: Array,
      default: () => []
    },
    totalTaggedGames: {
      type: Number,
      default: 0
    },
    totalAvailableGames: {
      type: Number,
      default: 0
    },
    taggedGamesCurrPage: {
      type: Number,
      default: 1
    },
    availableGamesCurrPage: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 12
    },
    processingGame: {
      type: String,
      default: null
    },
    isProcessingBatch: {
      type: Boolean,
      default: false
    },
    savingPositions: {
      type: Boolean,
      default: false
    },
    positionsChanged: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    duplicateGroups() {
      return this.getDuplicateGroups();
    }
  },
  methods: {
    onProviderSelected(providers) {
      this.$emit('provider-selected', providers);
    },
    
    onBrandSelected(brands) {
      this.$emit('brand-selected', brands);
    },
    
    onSearch(query) {
      this.$emit('search', query);
    },
    
    updateTaggedGames(games) {
      this.$emit('update:tagged-games', games);
    },
    
    onTaggedGamesPageChange(page) {
      this.$emit('tagged-games-page-change', page);
    },
    
    onAvailableGamesPageChange(page) {
      this.$emit('available-games-page-change', page);
    },
    
    onPositionsChanged() {
      this.$emit('positions-changed');
    },
    
    removeDuplicateGame(game) {
      this.$emit('remove-game', game);
    },
    
    removeGameFromTag(game) {
      this.$emit('remove-game', game);
    },
    
    addGameToTag(game) {
      this.$emit('add-game', game);
    },
    
    onTaggedGamesPerPageChange(perPage) {
      this.$emit('tagged-games-per-page-change', perPage);
    },
    
    onAvailableGamesPerPageChange(perPage) {
      this.$emit('available-games-per-page-change', perPage);
    }
  }
};
</script>
