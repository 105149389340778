<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <div class="d-flex align-items-center">
        <b-badge variant="light-primary" pill class="mr-2">
          {{ filteredGames.length }} juegos
        </b-badge>
        
        <!-- Selector de elementos por página -->
        <!-- <b-form-select
          v-model="localPerPage"
          size="sm"
          :options="perPageOptions"
          class="per-page-selector"
        /> -->
      </div>
      
      <div>
        <!-- Botón de filtro -->
        <b-button
          variant="outline-primary"
          size="sm"
          class="mr-2"
          @click="toggleFilters"
          v-b-tooltip.hover
          :title="showFilters ? 'Ocultar filtros' : 'Mostrar filtros'"
        >
          <feather-icon icon="FilterIcon" size="14" />
          <span class="ml-1">{{ hasActiveFilters ? `Filtros (${activeFiltersCount})` : 'Filtros' }}</span>
        </b-button>
        
        <b-button
          v-if="positionsChanged && games.length > 0"
          variant="primary"
          size="sm"
          class="mr-2"
          @click="$emit('save-positions')"
          :disabled="savingPositions"
        >
          <b-spinner v-if="savingPositions" small></b-spinner>
          <feather-icon v-else icon="SaveIcon" size="14" class="mr-1" />
          Guardar posiciones
        </b-button>
        
        <b-button
          v-if="games.length > 0"
          variant="outline-danger"
          size="sm"
          @click="confirmRemoveAll"
          :disabled="isProcessingBatch"
        >
          <b-spinner v-if="isProcessingBatch" small></b-spinner>
          <feather-icon v-else icon="TrashIcon" size="14" class="mr-1" />
          Quitar todos
        </b-button>
      </div>
    </div>

    <!-- Filtros (ocultos por defecto) -->
    <b-collapse v-model="showFilters" class="mb-3">
      <b-card>
        <b-form @submit.prevent>
          <b-row>
            <b-col md="3">
              <b-form-group label="Producto">
                <b-form-input
                  v-model="filters.productName"
                  placeholder="Buscar por producto"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Marca">
                <b-form-input
                  v-model="filters.brandName"
                  placeholder="Buscar por marca"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="ID del juego">
                <b-form-input
                  v-model="filters.gameId"
                  placeholder="Buscar por ID"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Nombre del juego">
                <b-form-input
                  v-model="filters.gameName"
                  placeholder="Buscar por nombre"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="resetFilters"
                :disabled="!hasActiveFilters"
                class="mr-2"
              >
                <feather-icon icon="XIcon" size="14" class="mr-1" />
                Limpiar filtros
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-collapse>

    <!-- Lista de juegos etiquetados con drag & drop -->
    <draggable
      v-model="gamesModel"
      class="games-grid"
      tag="div"
      handle=".drag-handle"
      @change="onDragChange"
    >
      <transition-group type="transition" name="flip-list">
        <game-card
          v-for="(game, index) in displayedGames"
          :key="game._id"
          :game="game"
          :position="index + 1"
          :show-drag-handle="true"
          action-type="remove"
          :processing="processingGame === game._id"
          :show-move-to-top="index > 0"
          @action-click="$emit('remove-game', game)"
          @move-to-top="moveGameToTop(game)"
          @position-change="handlePositionChange"
        />
      </transition-group>
    </draggable>

    <div v-if="displayedGames.length === 0" class="text-center text-muted p-5">
      <feather-icon icon="InboxIcon" size="36" />
      <p class="mt-2">{{ hasActiveFilters ? 'No se encontraron juegos con los filtros aplicados' : 'No hay juegos asignados a esta etiqueta' }}</p>
    </div>

    <!-- Paginación -->
    <!-- <div class="d-flex justify-content-center mt-3">
      <b-pagination
        v-if="filteredGames.length > 0 && totalGames > localPerPage"
        v-model="localCurrentPage"
        :total-rows="totalGames"
        :per-page="localPerPage"
        align="center"
        first-number
        last-number
      />
    </div> -->
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BSpinner,
  BPagination,
  BFormSelect,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCollapse,
  VBTooltip
} from "bootstrap-vue";
import draggable from "vuedraggable";
import GameCard from "./GameCard.vue";

export default {
  name: 'TaggedGames',
  components: {
    BBadge,
    BButton,
    BSpinner,
    BPagination,
    BFormSelect,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCollapse,
    draggable,
    GameCard
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    games: {
      type: Array,
      required: true
    },
    totalGames: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      default: 12
    },
    currentPage: {
      type: Number,
      default: 1
    },
    processingGame: {
      type: String,
      default: null
    },
    isProcessingBatch: {
      type: Boolean,
      default: false
    },
    savingPositions: {
      type: Boolean,
      default: false
    },
    positionsChanged: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      gamesModel: this.games,
      localCurrentPage: this.currentPage,
      localPerPage: this.perPage,
      perPageOptions: [
        { value: 12, text: '12 por página' },
        { value: 100, text: '100 por página' },
        { value: 200, text: '200 por página' },
        { value: 1000, text: '1000 por página' }
      ],
      filters: {
        productName: '',
        brandName: '',
        gameId: '',
        gameName: ''
      },
      showFilters: false // Ocultar filtros por defecto
    };
  },
  computed: {
    filteredGames() {
      if (!this.hasActiveFilters) {
        return this.games;
      }
      
      return this.games.filter(game => {
        const matchProduct = !this.filters.productName || 
          (game.product?.name && game.product.name.toLowerCase().includes(this.filters.productName.toLowerCase()));
        
        const matchBrand = !this.filters.brandName || 
          (game.brandName && game.brandName.toLowerCase().includes(this.filters.brandName.toLowerCase()));
        
        const matchGameId = !this.filters.gameId || 
          (game.gameId && game.gameId.toLowerCase().includes(this.filters.gameId.toLowerCase()));
        
        const matchGameName = !this.filters.gameName || 
          (game.gameName && game.gameName.toLowerCase().includes(this.filters.gameName.toLowerCase()));
        
        return matchProduct && matchBrand && matchGameId && matchGameName;
      });
    },
    
    displayedGames() {
      return this.filteredGames;
    },
    
    hasActiveFilters() {
      return Object.values(this.filters).some(value => value.trim() !== '');
    },
    
    activeFiltersCount() {
      return Object.values(this.filters).filter(value => value.trim() !== '').length;
    }
  },
  watch: {
    games(val) {
      this.gamesModel = val;
    },
    gamesModel(val) {
      this.$emit('update:games', val);
    },
    currentPage(newVal) {
      // Actualizar el valor local cuando cambia la prop
      this.localCurrentPage = newVal;
    },
    localCurrentPage(newVal) {
      // Emitir evento cuando cambia la página localmente
      if (newVal !== this.currentPage) {
        this.$emit('page-change', newVal);
      }
    },
    perPage(newVal) {
      this.localPerPage = newVal;
    },
    localPerPage(newVal) {
      if (newVal !== this.perPage) {
        this.$emit('per-page-change', newVal);
        // Resetear a la primera página cuando cambia el tamaño de página
        this.localCurrentPage = 1;
      }
    },
    filters: {
      handler() {
        this.$emit('filter-change', { ...this.filters });
        // Volver a la primera página cuando cambian los filtros
        this.localCurrentPage = 1;
      },
      deep: true
    },
    hasActiveFilters(newVal) {
      // Si se aplica algún filtro, mostrar automáticamente el panel de filtros
      if (newVal && !this.showFilters) {
        this.showFilters = true;
      }
    }
  },
  methods: {
    onDragChange() {
      this.$emit('positions-changed');
    },
    
    confirmRemoveAll() {
      this.$bvModal.msgBoxConfirm(
        "¿Está seguro que desea eliminar todos los juegos de esta etiqueta?", 
        {
          title: "Confirmar eliminación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          footerClass: "p-2",
          centered: true,
        }
      )
      .then(value => {
        if (value) {
          this.$emit('remove-all');
        }
      });
    },
    
    moveGameToTop(game) {
      // Encontrar el índice del juego
      const index = this.gamesModel.findIndex(item => item._id === game._id);
      
      if (index > 0) {
        // Crear una copia del array
        const updatedGames = [...this.gamesModel];
        
        // Quitar el juego de su posición actual
        const [movedGame] = updatedGames.splice(index, 1);
        
        // Insertar el juego al inicio del array
        updatedGames.unshift(movedGame);
        
        // Actualizar el modelo
        this.gamesModel = updatedGames;
        
        // Emitir evento de cambio de posiciones
        this.$emit('positions-changed');
      }
    },
    
    resetFilters() {
      this.filters = {
        productName: '',
        brandName: '',
        gameId: '',
        gameName: ''
      };
    },
    
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },

    handlePositionChange({ game, newPosition }) {
      // Validar que la nueva posición sea válida
      if (newPosition < 1 || newPosition > this.gamesModel.length) {
        this.$bvToast.toast('La posición debe estar entre 1 y ' + this.gamesModel.length, {
          title: 'Posición inválida',
          variant: 'warning',
          solid: true
        });
        return;
      }
      
      // Encontrar el índice actual del juego
      const currentIndex = this.gamesModel.findIndex(item => item._id === game._id);
      if (currentIndex === -1) return;
      
      // Calcular el índice de destino (restamos 1 porque los índices comienzan en 0)
      const targetIndex = newPosition - 1;
      
      // Si la posición es la misma, no hacer nada
      if (currentIndex === targetIndex) return;
      
      // Crear una copia del array para mantener la reactividad
      const updatedGames = [...this.gamesModel];
      
      // Quitar el juego de su posición actual
      const [movedGame] = updatedGames.splice(currentIndex, 1);
      
      // Insertar el juego en la nueva posición
      updatedGames.splice(targetIndex, 0, movedGame);
      
      // Actualizar el modelo
      this.gamesModel = updatedGames;
      
      // Emitir evento para indicar que las posiciones han cambiado
      this.$emit('positions-changed');
      
      // Mostrar mensaje de confirmación
      this.$bvToast.toast(`Juego "${game.gameName}" movido a la posición ${newPosition}`, {
        title: 'Posición actualizada',
        variant: 'success',
        solid: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.games-grid {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 58vh;
  overflow-y: auto;
  padding: 0.25rem;
}

// Animación para drag and drop
.flip-list-move {
  transition: transform 0.5s;
}

.per-page-selector {
  width: 150px;
}

// Animación para el panel de filtros
.collapse-enter-active, .collapse-leave-active {
  transition: all 0.3s;
}
.collapse-enter, .collapse-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
