<template>
  <b-card class="mb-0">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h5 class="mb-0">{{ $t('labels.tags') || 'Etiquetas' }}</h5>
      <b-dropdown variant="outline-primary" size="sm" right no-caret>
        <template #button-content>
          <feather-icon icon="FilterIcon" size="16" />
        </template>
        <b-dropdown-form>
          <b-form-group label="Estado:">
            <b-form-radio-group 
              v-model="tagStatusFilter"
              :options="[
                { text: 'Activos', value: true },
                { text: 'Inactivos', value: false }
              ]"
              stacked
              @change="onStatusFilterChange"
            />
          </b-form-group>
        </b-dropdown-form>
      </b-dropdown>
    </div>
    
    <!-- Búsqueda de tags -->
    <b-form-input 
      v-model="searchQuery" 
      placeholder="Buscar etiquetas"
      class="mb-2"
    />

    <!-- Lista de tags -->
    <b-overlay :show="loading" rounded="sm">
      <div class="tags-list">
        <b-card
          v-for="tag in filteredTags"
          :key="tag._id"
          no-body
          :class="['tag-card mb-2', selectedTagId === tag._id ? 'selected' : '']"
          @click="selectTag(tag)"
        >
          <div class="d-flex align-items-center">
            <div class="tag-icon mr-2">
              <feather-icon icon="TagIcon" :class="tag.status ? 'text-primary' : 'text-muted'" />
            </div>
            
            <div class="flex-grow-1">
              <h6 class="mb-0 text-truncate">{{ tag.name }}</h6>
              <small class="d-flex align-items-center">
                <b-badge :variant="tag.status ? 'light-success' : 'light-secondary'" pill class="mr-1">
                  {{ tag.status ? 'Activo' : 'Inactivo' }}
                </b-badge>
                <span class="ml-1">
                  {{ tag.gamesCount || 0}} juegos
                </span>
              </small>
            </div>

            <div class="tag-actions z-index-1">
              <b-dropdown no-caret right variant="link" class="p-0" toggle-class="p-0">
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-muted" />
                </template>
                <b-dropdown-item @click.stop="editTag(tag)">
                  <feather-icon icon="EditIcon" size="14" class="mr-1" />
                  Editar
                </b-dropdown-item>
                <b-dropdown-item @click.stop="confirmDeleteTag(tag._id)">
                  <feather-icon icon="TrashIcon" size="14" class="mr-1" />
                  Eliminar
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </b-card>
        
        <div v-if="filteredTags.length === 0" class="text-center p-3 text-muted">
          <feather-icon icon="SearchIcon" size="24" />
          <p class="mt-1 mb-0">No se encontraron etiquetas</p>
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BFormInput,
  BOverlay,
  BFormRadioGroup,
  BDropdown,
  BDropdownItem,
  BDropdownForm,
  BFormGroup
} from "bootstrap-vue";

export default {
  name: 'TagsList',
  components: {
    BCard,
    BBadge,
    BFormInput,
    BOverlay,
    BFormRadioGroup,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BFormGroup
  },
  props: {
    tags: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectedTagId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      searchQuery: '',
      tagStatusFilter: true
    };
  },
  computed: {
    filteredTags() {
      let filtered = this.tags;
      
      // Filtrar por estado
      if (this.tagStatusFilter !== null) {
        filtered = filtered.filter(tag => tag.status === this.tagStatusFilter);
      }
      
      // Filtrar por texto de búsqueda
      if (this.searchQuery.trim()) {
        const query = this.searchQuery.toLowerCase().trim();
        filtered = filtered.filter(tag => tag.name.toLowerCase().includes(query));
      }
      
      return filtered;
    }
  },
  methods: {
    selectTag(tag) {
      this.$emit('tag-selected', tag);
    },
    
    editTag(tag) {
      this.$emit('edit-tag', tag);
    },
    
    confirmDeleteTag(tagId) {
      this.$bvModal.msgBoxConfirm("¿Está seguro que desea eliminar esta etiqueta?", {
        title: "Confirmar eliminación",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "Eliminar",
        cancelTitle: "Cancelar",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if (value) {
          this.$emit('delete-tag', tagId);
        }
      });
    },
    
    onStatusFilterChange() {
      this.$emit('status-filter-change', this.tagStatusFilter);
    }
  }
};
</script>

<style lang="scss" scoped>
.tags-list {
  max-height: 75vh;
  overflow-y: auto;
}

.tag-card {
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
  }
  
  &.selected {
    border-left: 3px solid var(--primary);
    background-color: rgba(var(--primary-rgb), 0.05);
  }
}
</style>
