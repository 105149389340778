import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  methods: {
    /**
     * Carga la lista de etiquetas
     */
    async loadTags() {
      try {
        this.loadingTags = true;
        
        const response = await this.$store.dispatch("tagsModule/getTags", { status: this.tagStatusFilter });
        this.tags = response;
        
        // Mantener el tag seleccionado si existe
        if (this.selectedTag) {
          this.selectedTag = this.tags.find(tag => tag._id === this.selectedTag._id) || null;
        }
      } catch (error) {
        console.error("Error cargando tags:", error);
        this.showToast({
          title: "Error cargando etiquetas",
          variant: "danger"
        });
      } finally {
        this.loadingTags = false;
      }
    },

    /**
     * Crea o actualiza una etiqueta
     * @param {Object} tagData - Datos de la etiqueta a guardar
     * @param {Boolean} isEdit - Indica si es una edición o creación
     */
    async saveTag(tagData, isEdit = false) {
      try {
        if (isEdit) {
          // Actualizar tag existente
          await this.$store.dispatch("tagsModule/updateTag", tagData);
          
          this.showToast({
            title: "Etiqueta actualizada correctamente",
            variant: "success"
          });
          
          // Actualizar el tag seleccionado si es el mismo
          if (this.selectedTag && this.selectedTag._id === tagData._id) {
            this.selectedTag.name = tagData.name;
            this.selectedTag.status = tagData.status;
          }
        } else {
          // Crear nueva tag
          const result = await this.$store.dispatch("tagsModule/storeTag", tagData);
          this.showToast({
            title: "Etiqueta creada correctamente",
            variant: "success"
          });
          
          // Seleccionar el nuevo tag
          if (result && result._id) {
            await this.loadTags();
            this.selectedTag = this.tags.find(tag => tag._id === result._id);
            if (this.loadTaggedGames) {
              this.loadTaggedGames();
            }
          }
        }
        
        // Recargar tags
        await this.loadTags();
        return true;
      } catch (error) {
        console.error("Error guardando tag:", error);
        this.showToast({
          title: "Error guardando etiqueta",
          variant: "danger"
        });
        return false;
      }
    },

    /**
     * Elimina una etiqueta
     * @param {String} tagId - ID de la etiqueta a eliminar
     */
    async deleteTag(tagId) {
      try {
        await this.$store.dispatch("tagsModule/deleteTag", tagId);
        
        // Si el tag eliminado era el seleccionado, deseleccionarlo
        if (this.selectedTag && this.selectedTag._id === tagId) {
          this.selectedTag = null;
          this.taggedGames = [];
        }
        
        // Recargar lista de tags
        await this.loadTags();
        this.showToast({
          title: "Etiqueta eliminada correctamente",
          variant: "success"
        });
        return true;
      } catch (error) {
        console.error("Error eliminando tag:", error);
        this.showToast({
          title: "Error eliminando etiqueta",
          variant: "danger"
        });
        return false;
      }
    },

    /**
     * Muestra un toast con el mensaje especificado
     * @param {Object} options - Opciones del toast
     */
    showToast(options) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: options.title,
          icon: options.variant === "success" ? "CheckCircleIcon" : "AlertTriangleIcon",
          variant: options.variant,
        },
      });
    }
  }
};
