<template>
  <TagsManagement />
</template>

<script>
import TagsManagement from './TagsManagement.vue'

export default {
  components: {
    TagsManagement
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.scrollable {
  height: 300px !important;
  overflow-y: scroll !important;
}

.list-group {
  flex-direction: row !important;
}
</style>
