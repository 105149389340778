<template>
  <div class="tags-management">
    <b-card>
      <b-row>
        <!-- Header con título y acciones principales -->
        <b-col cols="12" class="mb-2">
          <div class="d-flex justify-content-between align-items-center">
            <h1 class="mb-0">{{ $t('labels.tags-management') || 'Gestión de Etiquetas' }}</h1>
            <b-button variant="primary" @click="showAddTagModal">
              <feather-icon icon="PlusIcon" size="16" class="mr-1" />
              {{ $t('labels.add-tag') || 'Añadir Etiqueta' }}
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row class="p-0">
        <!-- Panel izquierdo: Listado y filtrado de tags -->
        <b-col md="4" class="tags-panel p-0">
          <tags-list
            :tags="tags"
            :loading="loadingTags"
            :selected-tag-id="selectedTag ? selectedTag._id : null"
            @tag-selected="selectTag"
            @edit-tag="editTag"
            @delete-tag="confirmDeleteTag"
            @status-filter-change="onStatusFilterChange"
          />
        </b-col>

        <!-- Panel derecho: Detalles y gestión de juegos -->
        <b-col md="8" class="games-panel p-0">
          <games-panel
            :selected-tag="selectedTag"
            :loading="loadingGames"
            :loading-providers="loadingProviders"
            :loading-brands="loadingBrands"
            :providers-options="providersOptions"
            :brands-options="brandsOptions"
            :selected-provider="selectedProvider"
            :selected-brand="selectedBrand"
            :search-game-query="searchGameQuery"
            :status-filter="gamesStatusFilter"
            :tagged-games="taggedGames"
            :available-games="availableGames"
            :total-tagged-games="totalTaggedGames"
            :total-available-games="totalAvailableGames"
            :tagged-games-curr-page="taggedGamesCurrPage"
            :available-games-curr-page="availableGamesCurrPage"
            :per-page="perPage"
            :processing-game="processingGame"
            :is-processing-batch="isProcessingBatch"
            :saving-positions="savingPositions"
            :positions-changed="positionsChanged"
            @edit-tag="editTag"
            @delete-tag="confirmDeleteTag"
            @provider-selected="onProviderSelected"
            @brand-selected="onBrandSelected"
            @search="onSearchGames"
            @status-filter-change="onGamesStatusFilterChange"
            @update:tagged-games="taggedGames = $event"
            @tagged-games-page-change="loadTaggedGames"
            @available-games-page-change="loadAvailableGames"
            @tagged-games-per-page-change="updateTaggedGamesPerPage"
            @available-games-per-page-change="updateAvailableGamesPerPage"
            @add-game="addGameToTag"
            @remove-game="removeGameFromTag"
            @positions-changed="positionsChanged = true"
            @save-positions="savePositions"
            @add-all-games="addAllGamesToTag"
            @remove-all-games="confirmRemoveAllGames"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal para añadir/editar Tags -->
    <tag-form
      :visible.sync="tagModal.show"
      :tag="tagModal.tag"
      :saving="tagModal.saving"
      @save="saveTagFromModal"
    />
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import TagsList from "./components/tags/TagsList.vue";
import TagForm from "./components/tags/TagForm.vue";
import GamesPanel from "./components/games/GamesPanel.vue";
import tagsMixin from "./mixins/tagsMixin";
import gamesMixin from "./mixins/gamesMixin";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: 'TagsManagement',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    TagsList,
    TagForm,
    GamesPanel
  },
  mixins: [tagsMixin, gamesMixin],
  data() {
    return {
      // Estado general
      tags: [],
      selectedTag: null,
      searchTagQuery: "",
      tagStatusFilter: true,

      // Filtros de juegos
      selectedProvider: [],
      selectedBrand: [],
      searchGameQuery: "",
      gamesStatusFilter: true, // Nuevo filtro de estado (true = activos)
      providersOptions: [],
      brandsOptions: [],

      // Juegos
      availableGames: [],
      taggedGames: [],
      availableGamesCurrPage: 1,
      taggedGamesCurrPage: 1,
      totalAvailableGames: 0,
      totalTaggedGames: 0,
      perPage: 100,
      
      // Estados de carga
      loadingTags: false,
      loadingGames: false,
      loadingProviders: false,
      loadingBrands: false,
      processingGame: null,
      isProcessingBatch: false,
      savingPositions: false,
      positionsChanged: false,
      
      // Modal de Tag
      tagModal: {
        show: false,
        tag: null,
        saving: false
      },
    };
  },
  computed: {
    filteredTags() {
      let filtered = this.tags;
      
      // Filtrar por estado
      if (this.tagStatusFilter !== null) {
        filtered = filtered.filter(tag => tag.status === this.tagStatusFilter);
      }
      
      // Filtrar por texto de búsqueda
      if (this.searchTagQuery.trim()) {
        const query = this.searchTagQuery.toLowerCase().trim();
        filtered = filtered.filter(tag => tag.name.toLowerCase().includes(query));
      }
      
      return filtered;
    }
  },
  methods: {
    // ---- GESTIÓN DE TAGS ----
    onStatusFilterChange(status) {
      this.tagStatusFilter = status;
      this.loadTags();
    },
    
    async selectTag(tag) {
      this.selectedTag = tag;
      this.availableGamesCurrPage = 1;
      this.taggedGamesCurrPage = 1;
      this.positionsChanged = false;
      
      // Reset filters on tag change for better UX
      this.searchGameQuery = "";
      
      // Primero cargamos los juegos etiquetados
      await this.loadTaggedGames();
      
      // Cargar proveedores solo si no se han cargado aún
      if (this.providersOptions.length === 0) {
        await this.loadProviders();
      } else if (this.selectedProvider && this.selectedProvider.length > 0) {
        // Si ya hay un proveedor seleccionado, cargar juegos disponibles
        this.loadAvailableGames();
      }
    },
    
    showAddTagModal() {
      this.tagModal = {
        show: true,
        tag: null,
        saving: false
      };
    },
    
    editTag(tag) {
      this.tagModal = {
        show: true,
        tag: tag,
        saving: false
      };
    },
    
    async saveTagFromModal(tagData, isEdit) {
      try {
        this.tagModal.saving = true;
        const success = await this.saveTag(tagData, isEdit);
        if (success) {
          this.tagModal.show = false;
        }
      } finally {
        this.tagModal.saving = false;
      }
    },
    
    confirmDeleteTag(tagId) {
      this.$bvModal.msgBoxConfirm("¿Está seguro que desea eliminar esta etiqueta?", {
        title: "Confirmar eliminación",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "Eliminar",
        cancelTitle: "Cancelar",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if (value) {
          this.deleteTag(tagId);
        }
      });
    },
    
    // ---- GESTIÓN DE PROVEEDORES Y MARCAS ----
    async loadProviders() {
      try {
        this.loadingProviders = true;
        const response = await this.$store.dispatch("tagsModule/getProducts");
        this.providersOptions = response;
        
        // Preseleccionar el primer proveedor para mejor UX si no hay uno seleccionado
        if (this.providersOptions.length > 0 && (!this.selectedProvider || this.selectedProvider.length === 0)) {
          this.selectedProvider = [this.providersOptions[0]._id];
          // Al seleccionar el proveedor se cargarán las marcas y los juegos
          await this.onProviderSelected(this.selectedProvider);
        }
      } catch (error) {
        console.error("Error cargando proveedores:", error);
        this.showToast({
          title: "Error cargando proveedores",
          variant: "danger"
        });
      } finally {
        this.loadingProviders = false;
      }
    },
    
    async onProviderSelected(providerIds) {
      if (!providerIds || providerIds.length === 0) return;
      
      try {
        this.loadingBrands = true;
        this.selectedBrand = [];
        this.brandsOptions = [];
        
        // Guardar los proveedores seleccionados antes de cargar las marcas
        this.selectedProvider = providerIds;
        
        // Cargar marcas para todos los proveedores seleccionados
        const brandsPromises = providerIds.map(providerId => 
          this.$store.dispatch("brandModule/index", providerId)
        );
        
        const brandsResponses = await Promise.all(brandsPromises);
        
        // Unir todas las marcas y eliminar duplicados
        const allBrands = brandsResponses.flatMap(response => response.data?.brands || []);
        const uniqueBrands = [];
        const seenBrandIds = new Set();
        
        allBrands.forEach(brand => {
          if (!seenBrandIds.has(brand._id)) {
            seenBrandIds.add(brand._id);
            uniqueBrands.push(brand);
          }
        });
        
        this.brandsOptions = uniqueBrands;
        
        // Recargar juegos con el nuevo filtro
        // Solo si hay un tag seleccionado
        if (this.selectedTag) {
          await this.loadAvailableGames();
        }
      } catch (error) {
        console.error("Error cargando marcas:", error);
        this.showToast({
          title: "Error cargando marcas",
          variant: "danger"
        });
      } finally {
        this.loadingBrands = false;
      }
    },
    
    async onBrandSelected(brandsId) {
      this.selectedBrand = brandsId;
      
      // Recargar juegos solo si hay un tag seleccionado
      if (this.selectedTag) {
        await this.loadAvailableGames();
      }
    },
    
    // ---- GESTIÓN DE JUEGOS ----
    async onSearchGames(query) {
      this.searchGameQuery = query;
      this.availableGamesCurrPage = 1;
      await this.loadAvailableGames();
    },
    
    async addAllGamesToTag() {
      if (!this.selectedTag || !this.selectedProvider || this.selectedProvider.length === 0 || this.availableGames.length === 0) return;
      
      try {
        this.isProcessingBatch = true;
        
        // Preparar datos para la actualización masiva
        const gameIds = this.availableGames.map(game => ({
          gameId: game.gameId,
          idGame: game._id
        }));
        
        // Para cada proveedor seleccionado, debemos agregar los juegos
        for (const providerId of this.selectedProvider) {
          await this.$store.dispatch("tagsModule/addMultipleGamesToTag", {
            tagId: this.selectedTag._id,
            provider: providerId,
            games: gameIds
          });
        }
        
        // Recargar ambas listas de juegos
        await Promise.all([
          this.loadAvailableGames(),
          this.loadTaggedGames()
        ]);
        
        this.showToast({
          title: "Todos los juegos añadidos a la etiqueta",
          variant: "success"
        });
      } catch (error) {
        console.error("Error añadiendo juegos a la etiqueta:", error);
        this.showToast({
          title: "Error añadiendo juegos a la etiqueta",
          variant: "danger"
        });
      } finally {
        this.isProcessingBatch = false;
      }
    },
    
    confirmRemoveAllGames() {
      this.$bvModal.msgBoxConfirm(
        "¿Está seguro que desea eliminar todos los juegos de esta etiqueta?", 
        {
          title: "Confirmar eliminación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          footerClass: "p-2",
          centered: true,
        }
      )
      .then(value => {
        if (value) {
          this.removeAllGamesFromTag();
        }
      });
    },
    
    async removeAllGamesFromTag() {
      if (!this.selectedTag || this.taggedGames.length === 0) return;
      
      try {
        this.isProcessingBatch = true;
        
        await this.$store.dispatch("tagsModule/removeAllGamesFromTag", {
          tagId: this.selectedTag._id
        });
        
        // Recargar juegos
        this.taggedGames = [];
        this.totalTaggedGames = 0;
        await this.loadAvailableGames();
        
        this.showToast({
          title: "Todos los juegos han sido eliminados de la etiqueta",
          variant: "success"
        });
      } catch (error) {
        console.error("Error eliminando juegos de la etiqueta:", error);
        this.showToast({
          title: "Error eliminando juegos de la etiqueta",
          variant: "danger"
        });
      } finally {
        this.isProcessingBatch = false;
      }
    },
    
    // ---- GESTIÓN DE FILTROS DE JUEGOS ----
    onGamesStatusFilterChange(status) {
      this.gamesStatusFilter = status;
      this.availableGamesCurrPage = 1;
      this.loadAvailableGames();
    },
    
    // ---- GESTIÓN DE PAGINACIÓN ----
    updateTaggedGamesPerPage(perPage) {
      this.perPage = perPage;
      this.taggedGamesCurrPage = 1;
      this.loadTaggedGames();
    },
    
    updateAvailableGamesPerPage(perPage) {
      this.perPage = perPage;
      this.availableGamesCurrPage = 1;
      this.loadAvailableGames();
    }
  },
  watch: {
    searchTagQuery() {
      // La búsqueda se hace localmente, no es necesario recargar del servidor
    }
  },
  mounted() {
    // Cargar tags al montar el componente
    this.loadTags();
  }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.tags-management {
  .tags-panel {
    .tags-list {
      max-height: 75vh;
      overflow-y: auto;
    }

    .tag-card {
      cursor: pointer;
      transition: all 0.2s ease;
      
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 3px 10px rgba(0,0,0,0.1);
      }
      
      &.selected {
        border-left: 3px solid var(--primary);
        background-color: rgba(var(--primary-rgb), 0.05);
      }
    }
  }
  
  .games-panel {
    .filter-card {
      background-color: rgba(0, 0, 0, 0.02);
    }
    
    .games-grid {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      max-height: 58vh;
      overflow-y: auto;
      padding: 0.25rem;
    }
    
    .game-card {
      transition: all 0.2s ease;
      border: 1px solid #eee;
      
      &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }
      
      .game-thumbnail {
        object-fit: cover;
        background-color: #f8f8f8;
      }
      
      .game-details {
        overflow: hidden;
        
        h6 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      
      .position-badge {
        min-width: 24px;
        text-align: center;
      }
    }
  }
  
  .drag-handle {
    cursor: move !important;
  }
  
  // Animación para drag and drop
  .flip-list-move {
    transition: transform 0.5s;
  }
  
  .duplicate-games-container {
    max-height: 60vh;
    overflow-y: auto;
    
    .duplicate-title {
      background-color: rgba(var(--warning-rgb), 0.1);
      padding: 0.5rem;
      border-radius: 0.25rem;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
  }
}
</style>
